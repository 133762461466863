import QueryHandlerInterface from '@/core/application/query/QueryHandlerInterface'
import EDSQueryRepositoryInterface from '@/core/domain/repository/modules/EDSQueryRepositoryInterface'
import GetHashesQuery from './GetHashesQuery'

export default class GetHashesHandler implements QueryHandlerInterface
{
    private repo: EDSQueryRepositoryInterface;

    constructor(repo: EDSQueryRepositoryInterface)
    {
        this.repo = repo;
    }

    execute(query: GetHashesQuery): Promise<any>
    {
        return this.repo.getHashes(
            query.getRegistryId(),
            query.getRecordId(),
            query.getCertificate()
        );
    }
}